import { createRef } from "react";
import Image from "gatsby-image";
/** @jsx jsx */
import { css, jsx } from "@emotion/react"; // eslint-disable-line no-unused-vars
import tw from "tailwind.macro";
import anime from "animejs";
import ScrollTrigger from "react-scroll-trigger";

const ClientList = ({ clients }) => (
  <div className="flex flex-col w-full">
    {clients.map((client, i) => {
      let ref = createRef();
      return (
        <ScrollTrigger
          className={`${
            i % 2 === 0 ? "self-end md:text-right" : "self-start"
          } md:w-5/12 w-full relative ${i === 0 ? "md:-mt-8" : "md:-mt-48"} ${
            i < clients.length - 1 ? "mb-16" : ""
          }`}
          key={i}
          onEnterOnce
          onEnter={() => {
            // ANIMATE: client square
            anime({
              targets: ref.current,
              delay: 200,
              duration: 100,
              keyframes: [
                {
                  backgroundColor: "#083C54",
                },
                {
                  scale: 1.1,
                },
              ],
            });
          }}
          throttleScroll={200}
        >
          <a href={client.link} className="trans-all hover:text-blue-tertiary">
            <div className={`absolute md:visible invisible w-full `}>
              <div
                ref={ref}
                className="ani"
                css={css`
                  ${i % 2 === 0
                    ? css`
                        right: -20px;
                      `
                    : css`
                        left: -20px;
                      `}
                  position: absolute;
                  margin-top: -20px;
                  height: 180px;
                  width: 180px;
                  ${tw`bg-blue-secondary`}
                `}
              ></div>
            </div>
            <Image
              className="border mb-4 shadow-xl hover:shadow-2xl"
              fluid={client.preview.asset.fluid}
            />
            <h3 className="mb-2 text-2xl font-bold font-sans">{client.name}</h3>
            <p>{client.description}</p>
          </a>
        </ScrollTrigger>
      );
    })}
  </div>
);

export default ClientList;

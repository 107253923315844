import { useEffect } from "react";
import { graphql, Link } from "gatsby";
/** @jsx jsx */
import { css, jsx } from "@emotion/react"; // eslint-disable-line no-unused-vars
import tw from "tailwind.macro";

import BlockContent from "@sanity/block-content-to-react";
import ScrollTrigger from "react-scroll-trigger";
import anime from "animejs";

import Layout from "~layouts/main";
import SEO from "~utils/seo";
import PreFooter from "~components/PreFooter";
import ClientList from "~components/ClientList";
import BlockSection from "~components/BlockSection";

export const query = graphql`
  query IndexPageQuery {
    page: sanityAbout {
      mission: _rawMission
      advantages {
        body
        icon
        title
      }
      testimonials: featuredTestimonials {
        name
        company
        body
        link
      }
      clients: featuredClients {
        link
        name
        description
        preview {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;

const HeroShape = ({ reverse }) => (
  <div
    className={`bg-light flex w-full h-full ${
      reverse ? "flex-row-reverse" : ""
    }`}
  >
    <div className="ani ani-hero-shape-2 opacity-50 w-1/3 h-full bg-blue-secondary"></div>
    <div className="ani ani-hero-shape-1 opacity-50 w-1/3 h-full bg-blue-tertiary"></div>
    <div className="ani ani-hero-shape-0 opacity-50 w-1/3 h-full bg-blue-primary"></div>
  </div>
);
const HeroHyper = () => (
  <span
    css={css`
      transform: skew(-15deg);
      font-size: 40px;
      ${tw`uppercase font-bold inline-block`}
    `}
  >
    hyper
  </span>
);

const IndexPage = ({ data }) => {
  useEffect(() => {
    // ANIMATE: hero section
    anime
      .timeline({
        easing: "easeInOutQuad",
      })
      .add({
        targets: [
          ".ani.ani-hero-shape-0",
          ".ani.ani-hero-shape-1",
          ".ani.ani-hero-shape-2",
        ],
        opacity: ["0.5", "1"],
        duration: 200,
        delay: anime.stagger(100, { start: 200 }),
      });
  });

  return (
    <Layout>
      <SEO
        title="Paonix: Bespoke JAMstack Web Development"
        description="A hyper-small web design agency dedicated to creating your hyper-awesome site. We craft lightning-fast, secure, and intuitively managable web platforms."
      />
      <section
        css={css`
          height: calc(100vh - 6rem);
          overflow: hidden;
          ${tw`flex flex-col justify-between items-center bg-blue-primary`}
        `}
      >
        <HeroShape reverse={true} />
        <div className="ani ani-hero-text text-center text-3xl leading-relaxed font-mono text-light p-12">
          <p>
            <span>The </span>
            <span className="md:text-4xl text-3xl font-sans tracking-wider">
              <HeroHyper />
              -small
            </span>
          </p>
          <p>web design agency dedicated to building your</p>
          <p>
            <span className="md:text-4xl text-3xl font-sans tracking-wider">
              <HeroHyper />
              -awesome
            </span>
            <span> website.</span>
          </p>
        </div>
        <HeroShape />
      </section>
      <BlockSection invert={false}>
        <BlockContent blocks={data.page.mission} />
      </BlockSection>
      <section className="p-16 bg-blue-primary text-light">
        <p className="mb-16">
          At Paonix, we utilize a development stack called the{" "}
          <strong>JAMstack</strong>. Did someone say JAM and toast?
        </p>
        <ScrollTrigger
          className="flex flex-wrap -mx-8"
          onEnter={() =>
            // ANIMATE: advantages
            anime({
              targets: ".ani.ani-advantages",
              opacity: 1,
              delay: anime.stagger(150, { start: 500 }),
            })
          }
        >
          {data.page.advantages.map((advantage, i) => (
            <div
              className="ani ani-advantages md:w-1/3 w-full md:mb-0 mb-16 text-center px-4 opacity-0"
              key={i}
            >
              <img
                className="mb-8 mx-auto"
                alt={advantage.title}
                css={css`
                  width: 5em;
                  filter: invert(0.9);
                `}
                src={`data:image/svg+xml;utf8,${advantage.icon}`}
              />
              <h3 className="text-2xl font-bold font-sans mb-4">
                {advantage.title}
              </h3>
              <p className="text-left">{advantage.body}</p>
            </div>
          ))}
        </ScrollTrigger>
      </section>
      <section className="p-16">
        <h2 className="text-3xl font-bold font-sans mb-8">Recent Work</h2>
        <ClientList clients={data.page.clients} />
      </section>
      <section className="p-16 bg-blue-secondary text-light">
        <h2 className="text-3xl font-bold font-sans mb-8">Testimonials</h2>
        <div className="flex flex-wrap -mx-6 -mb-8 h-full items-center">
          {data.page.testimonials.map((testimonial, i) => (
            <div className="xl:w-1/3 w-full mb-8" key={i}>
              <div className="trans-all p-8 border rounded-sm opacity-75 hover:opacity-100 bg-light shadow-xl hover:shadow-2xl text-black mx-6">
                <div className="flex justify-center text-center">
                  <i className="fas fa-quote-left text-4xl text-gray-500 mr-8 sm:block hidden" />
                  <div>
                    <p className="mb-2">{testimonial.body}</p>
                    <h3 className="font-sans text-2xl">{testimonial.name}</h3>
                    {testimonial.company ? (
                      <h3 className="font-sans text-xl opacity-75">
                        {testimonial.company}
                      </h3>
                    ) : null}
                    <a
                      className="button trans-all text-light mt-4 inline-block"
                      href={testimonial.link}
                    >
                      Read More
                    </a>
                  </div>
                  <i className="fas fa-quote-right text-4xl text-gray-500 ml-8 sm:block hidden" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <PreFooter title="Interested?">
        <p>
          Head on over to the{" "}
          <Link className="button" to="/contact">
            contact
          </Link>{" "}
          page to talk to us.
        </p>
      </PreFooter>
    </Layout>
  );
};

export default IndexPage;
